.PersonalInfo-Content {
  --label-gap: 16px;

  word-wrap: anywhere;
}

@media (width <= 1023px) {
  .PersonalInfo-Content {
    --label-gap: initial;
  }
}
