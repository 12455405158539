/* TODO: Remove .WithLabel from here somehow */

.Label {
  display: block;

  & + &:not(&_no-margin_top),
  & + .WithLabel {
    margin-top: 1rem;
  }
}

.Label-Text {
  margin-bottom: 0.375em;
}

.Label-Postfield {
  margin-top: 0.375em;
  line-height: 1;
}

.Label-TextAction {
  float: right;
}

.Label-Field {
  .ie & {
    .ColumnarLayout {
      display: block;
    }

    .ColumnarLayout-Column {
      display: inline-block;
    }
  }
}

.Label_text_center {
  text-align: center;
}

.Label_text_bold {
  font-weight: 500;
}

.Label_label-position_top-at-lowercase {
  .Label-Text {
    margin-top: -0.5em;
  }
}

.Label_label-position_tighter {
  .Label-Text {
    margin-bottom: -0.15em;
  }
}

.Label_label-position_tight {
  .Label-Text {
    margin-bottom: -0.375em;
  }
}

.Label_size_small {
  .Label-Text,
  .Label-Postfield {
    font-size: 0.75em;
  }
}

.Label_size_smaller {
  .Label-Text,
  .Label-Postfield {
    font-size: 0.875rem;
  }
}

.Label_size_smallerWithCheckbox {
  .Label-Field {
    padding-top: 0.125rem;
  }
}

.Label_size_normal {
  .Label-Text,
  .Label-Postfield {
    font-size: 1rem;
  }
}

.Label_size_normalWithCheckbox {
  .Label-Field {
    padding-top: 0.1875rem;
  }
}

.Label_color_darkgray {
  color: var(--content-on-background-additional);
}

.Label_color_lightgray {
  color: var(--content-on-background-minor);
}

.Label_margin_top {
  margin-top: 1em;
}

.Label_margin_bottom {
  margin-bottom: 1em;
}

.Label_no-margin_top {
  margin-top: 0;
}

.Label_disabled {
  & + &,
  & + .WithLabel {
    margin-top: 2rem !important;
  }

  .Label-Text {
    color: var(--content-on-background-additional);
    font-size: 0.875em;
  }

  &.Label_size_small {
    .Label-Text {
      font-size: 0.75em;
    }
  }
}

.Label_inline {
  display: inline-block;
  white-space: nowrap;

  & + &,
  & + .WithLabel {
    margin-top: auto !important;
    margin-left: 1em;
  }

  .Label-Field,
  .Label-Text,
  .Label-Postfield {
    display: inline-block;
    vertical-align: middle;
  }
}

.Label_inlineClm {
  display: inline-block;
  white-space: nowrap;

  & + &,
  & + .WithLabel {
    margin-top: auto !important;
    margin-left: 1em;
  }
}

.Label_flex {
  display: flex;
  gap: var(--label-gap);

  .Label-Text {
    flex-shrink: 0;
    white-space: normal;
  }

  .Label-Field {
    flex-shrink: 0;
    text-align: left;
  }
}

.Label_flex_labelGrow {
  .Label-Text {
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.Label_flex_fieldGrow {
  .Label-Field {
    flex-grow: 1;
  }
}

.Label_flex_inlineStyleForm {
  & + &,
  & + .WithLabel {
    padding-top: 1.75rem;
    margin-top: auto !important;
  }

  > .Label-Text {
    flex-basis: 8rem;
    margin-top: 0.375em;
    margin-bottom: 0;
    color: var(--content-on-background-additional);
  }

  .Label-Field {
    position: relative;
    flex-shrink: 1;
    line-height: 2rem;
  }

  .Label-Field .ErrorMessage {
    position: absolute;
    top: -1rem;
    font-size: 0.75em;
    line-height: 1;
  }
}

.Label_flex_inlineStyleFormDisabled {
  & + &,
  & + .WithLabel {
    padding-top: 1rem;
  }
}

.Label_flex_inlineStyleFormMultiline {
  flex-wrap: wrap;

  & + &,
  & + .WithLabel {
    padding-top: 1rem;
  }

  .Label-Text {
    flex-grow: 1;
    line-height: 1.25rem;
  }

  .Label-Field {
    width: 100%;
    line-height: 1.25rem;
  }
}

.Label_flex_inline {
  display: inline-flex;
}

.Label_noMarginBottom {
  .Label-Text {
    margin-bottom: 0;
  }
}
